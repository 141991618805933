import React, { useState, useEffect } from "react";
import style from "./about.module.css";
const About = ({ data, lang }) => {
  const text = data.aboutUs_des.slice(0, 300);
  const [showbutton, setShowButton] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [showLessButton, setShowLessButton] = useState(false);
  useEffect(() => {
    if (data.aboutUs_des.length > 250) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [data.aboutUs_des.length]);

  const handleClicked = () => {
    setShowButton(false);
    setClicked(true);
    setShowLessButton(true);
  };
  const handleLessClicked = () => {
    setShowButton(true);
    setClicked(false);
    setShowLessButton(false);
  };
  return (
    <div id="about" className="container pt-5 mt-5">
      <div className="d-flex justify-content-between align-items-center">
        <div className="p-0 m-0 col-12 col-md-6 mb-3 mb-md-0">
          <h2 className={`${style.title} mb-4 mx-0 mt-0 p-0`}>
            {data.aboutUs_title}
          </h2>
          <p className={`mx-0 mt-0 mb-5 ${style.desc}`}>{data.aboutUs_des}</p>
          <a className="text-white btn" href={data.aboutUs_Link}>
            {lang === "en" ? "read more" : "مشاهدة المزيد"}
          </a>
        </div>
        <div className="">
          <img
            loading="lazy"
            className={style.img}
            src={data.aboutUs_img}
            alt="about/img"
          />
        </div>
      </div>
    </div>
  );
};

export default About;

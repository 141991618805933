import React from "react";
import style from "./footer.module.css";
const Footer = ({ lang }) => {
  return (
    <div className={`${style.mainDiv} m-0 p-0`}>
      <div className="container text-center m-0 p-0">
        <p className="m-0 p-0">
          &#169; {lang === "en" ? "powerd by" : "تم التنفيذ بواسطة"}{" "}
          <a
            className="text-white"
            href="https://technomasr.com/"
            target="_blank"
          >
            technomasr
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;

// about img
import aboutImg from "../assets/about.jpg";
import partnerImg from "../assets/partner.png";
import slider1 from "../assets/test.png";
import slider2 from "../assets/slider-02.jpeg";
import slider3 from "../assets/slider-03.jpeg";
import slider4 from "../assets/slider-04.jpeg";
export const slider = [
  {
    img: slider1,
    textOne: "welcome to",
    textTwo: "good",
    greenText: "life",
    textThree: "gym",
  },
  {
    img: slider2,
    textOne: "welcome",
    textTwo: "from",
    greenText: "megz",
  },
  {
    img: slider3,
    textOne: "hello",
    textTwo: "world",
  },
  {
    img: slider4,
    textOne: "I'm My",
    textTwo: "only",
    greenText: "Hope",
    textThree: "gym",
  },
];
export const nav = [
  {
    title: "home",
    arabicTitle: "الرئيسية",
    path: "/",
  },
  {
    title: "statistics",
    arabicTitle: "الاحصائيات",
    path: "#stat",
  },
  {
    title: "about",
    arabicTitle: "من نحن",
    path: "#about",
  },
  {
    title: "products",
    arabicTitle: "المنتجات",
    path: "https://ecom2.technomasr.com/products",
  },
];
export const stats = [
  {
    id: 1,
    number: 76,
    desc: "Community Members",
  },
  {
    id: 2,
    number: 128,
    desc: "Podcast Subscribers",
  },
  {
    id: 3,
    number: 128,
    desc: "Daily Listeners",
  },
];
export const about = {
  title: "about us",
  desc: "I help people to discover their true potential and live a fulfilling life...Discover the simple 3 steps that I discovered to hack productivity. It just works and it is begin using backed by science. Wanna transform your life?",
  btnText: "view more",
  img: aboutImg,
};
export const serv1 = [
  {
    title:
      "Face masks   .......................................          $48.00",
    dessc: "The versions have evolved over the sometimes by accident.",
  },
  {
    title:
      "Face masks   .......................................          $48.00",
    dessc: "The versions have evolved over the sometimes by accident.",
  },
  {
    title:
      "Face masks   .......................................          $48.00",
    dessc: "The versions have evolved over the sometimes by accident.",
  },
  {
    title:
      "Face masks   .......................................          $48.00",
    dessc: "The versions have evolved over the sometimes by accident.",
  },
  {
    title:
      "Face masks   .......................................          $48.00",
    dessc: "The versions have evolved over the sometimes by accident.",
  },
];
export const partner = [
  partnerImg,
  partnerImg,
  partnerImg,
  partnerImg,
  partnerImg,
  partnerImg,
  partnerImg,
];

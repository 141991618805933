import React, { useState, useEffect } from "react";
import style from "./services.module.css";
import ServicesCard from "./servicesCard/ServicesCard";
const Services = ({ data, lang, servicesTitle, servicesDesc }) => {
  const [Reversed, setIsReversed] = useState(false);

  return (
    <>
      <div className="d-block d-md-none">
        <div id="services" className="container pt-5">
          <div className="text-center d-flex flex-column align-items-center gap-1">
            <p className={`m-0 p-0 text-uppercase ${style.title}`}>
              our services
            </p>
            <p className={`m-0 p-0 fw-bold ${style.desc}`}>
              cover different applications of plastics industries
            </p>
          </div>

          <div className="mt-5">
            {data.map((item, index) => (
              <ServicesCard
                key={index}
                data={item}
                Reversed={index % 2 === 0 ? false : true}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="d-none d-md-block">
        <div id="services" className="pt-5">
          <div className="text-center d-flex flex-column align-items-center gap-1">
            <p className={`m-0 p-0 text-uppercase ${style.title}`}>
              {servicesTitle}
            </p>
            <p className={`m-0 p-0 fw-bold ${style.desc}`}>{servicesDesc}</p>
          </div>
          <div className="mt-5">
            {data.map((item, index) => (
              <ServicesCard
                key={index}
                data={item}
                Reversed={index % 2 === 0 ? false : true}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
/*
    <div className="d-block d-md-none">
        <div id="services" className="container py-5">
          <div className="text-center d-flex flex-column align-items-center gap-1">
            <p className={`m-0 p-0 text-uppercase ${style.title}`}>
              our services
            </p>
            <p className={`m-0 p-0 fw-bold ${style.desc}`}>
              cover different applications of plastics industries
            </p>
          </div>
       
        </div>
      </div>
     
*/

import React, { useState, useEffect } from "react";
import Meta from "./components/meta/Meta";
import Navbar from "./components/layout/navbar/Navbar";
import Home from "./pages/Home";
import Footer from "./components/layout/footer/Footer";
import { nav } from "./fakers/data";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useQuery, QueryClient, QueryClientProvider } from "react-query";
import { request } from "./components/utils/axios.js";
import Spinner from "./components/utils/spinner/Spinner.jsx";

// Create a shared QueryClient instance
const queryClient = new QueryClient();

const App = () => {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <QueryWrapper />
      </QueryClientProvider>
    </Router>
  );
};

const QueryWrapper = () => {
  const [lang, setLang] = useState(
    localStorage.getItem("lang")
      ? JSON.parse(localStorage.getItem("lang"))
      : "en"
  );
  useEffect(() => {
    localStorage.setItem("lang", JSON.stringify(lang));
  }, [lang]);
  useEffect(() => {
    document.documentElement.setAttribute("lang", lang);
    if (lang === "ar") {
      document.getElementsByTagName("body")[0].style.direction = "rtl";
      document.getElementsByTagName("body")[0].style.fontFamily =
        "'Noto Kufi Arabic', sans-serif";
    } else {
      document.getElementsByTagName("body")[0].style.direction = "ltr";
      document.getElementsByTagName("body")[0].style.fontFamily =
        "'Montserrat', sans-serif";
    }
  }, [lang]);
  const fetchData = async () => {
    return await request({ url: "/settings" });
  };

  const { data, isLoading } = useQuery("home", fetchData);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Meta
        fav={data.data.data.images.fav}
        title={data.data.data.site_status.siteTitle}
      />
      <Navbar
        logo={data.data.data.images.logo}
        data={nav}
        lang={lang}
        setLang={setLang}
        product={data.data.data.productLinks}
      />
      <Routes>
        <Route
          path="/"
          element={
            <Home
              servicesTitle={data.data.data.serviceSectiion.serviceTitle}
              servicesDesc={data.data.data.serviceSectiion.serviceDes}
              lang={lang}
            />
          }
        />
      </Routes>
      <Footer lang={lang} />
    </>
  );
};

export default App;

/*
  import React from "react";
import Meta from "./components/meta/Meta";
import Navbar from "./components/layout/navbar/Navbar";
import { nav, slider, stats, about, serv1, partner } from "./fakers/data";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./components/layout/footer/Footer";
import { QueryClientProvider, QueryClient, useQuery } from "react-query";

import { request } from "./components/utils/axios.js";
import Spinner from "./components/utils/spinner/Spinner.jsx";
const App = () => {
  const queryClient = new QueryClient();
  const fetchData = async () => {
    return await request({ url: "/settings" });
  };
  const { data, isLoading } = useQuery("home", fetchData, {
    cacheTime: 1800000,
    staleTime: 1800000,
  });
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <QueryClientProvider client={queryClient}>
          <Router>
            <Meta
              fav={data.data.data.fav}
              title={data.data.data.site_status.siteTitle}
            />
            <Navbar logo={data.data.data.images.logo} data={nav} />
            <Routes>
              <Route
                path="/"
                element={
                  <Home
                    stats={stats}
                    about={about}
                    services={serv1}
                    partner={partner}
                    slider={slider}
                  />
                }
              />
            </Routes>
            <Footer />
          </Router>
        </QueryClientProvider>
      )}
    </>
  );
};

export default App;
*/

import React from "react";
import style from "./ServicesCard.module.css";
const ServicesCard = ({ data, Reversed }) => {
  console.log("reversed", Reversed);
  return (
    <div
      className={`${
        Reversed ? "row flex-row-reverse" : "row"
      } align-items-center`}
    >
      <div className={`col-12 col-md-6 mb-3 p-0 mb-md-0 ${style.imgContainer}`}>
        <div
          style={{
            position: "relative",
            backgroundImage: `url(${data.photo})`,
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            width: "100%",
            height: "100%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
      <div className={`col-12 col-md-6 mb-3 mb-md-0 pb-3`}>
        <h3 className="mx-0 mt-0 mb-3 px-3 px-md-4">{data.title}</h3>
        <p className="m-0 px-3 px-md-4">{data.description}</p>
      </div>
    </div>
  );
};

export default ServicesCard;

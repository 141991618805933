import React, { useEffect, useState } from "react";
import style from "./Navbar.module.css";
import { HashLink } from "react-router-hash-link";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
const Navbar = ({ data, logo, lang, setLang, product }) => {
  const [bg, setBg] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  useEffect(() => {
    const handleBg = () => {
      if (window.scrollY >= 90) {
        setBg(true);
      } else {
        setBg(false);
      }
    };
    document.addEventListener("scroll", handleBg);
    return () => document.removeEventListener("scroll", handleBg);
  }, []);
  return (
    <div
      className={` pt-3 pt-md-0 ${style.navContainer} ${
        bg ? style.showBg : style.hideBg
      }`}
    >
      <div className="container  d-flex align-items-center justify-content-between">
        <img
          loading="lazy"
          alt="logo/img"
          src={logo}
          className={`${style.logo} `}
        />

        <ul className="m-0 p-0 d-none  d-md-flex align-items-center text-white gap-3">
          {data.map((item, index) => (
            <li key={index}>
              {item.title === "products" || item.arabicTitle === "المنتجات" ? (
                <a
                  className={`fw-bold ${bg ? "text-black" : "text-white"}`}
                  href={product}
                >
                  {lang === "en" ? item.title : item.arabicTitle}
                </a>
              ) : (
                <HashLink
                  className={`   fw-bold ${bg ? "text-black" : "text-white"}`}
                  to={item.path}
                >
                  {lang === "en" ? item.title : item.arabicTitle}
                </HashLink>
              )}
            </li>
          ))}
          <div className="dropdown">
            <button
              class="langbtn  dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {lang}
            </button>
            <ul class="dropdown-menu">
              <li
                onClick={() => {
                  setLang("ar");
                  window.location.reload();
                }}
                class="dropdown-item"
              >
                {lang === "en" ? "arabic" : "العربية"}
              </li>
              <li
                onClick={() => {
                  setLang("en");
                  window.location.reload();
                }}
                class="dropdown-item"
              >
                {lang === "ar" ? "الإنجليزية" : "english"}
              </li>
            </ul>
          </div>
        </ul>

        <AiOutlineMenu
          onClick={() => setShowSidebar(true)}
          className={`${bg ? "text-black" : "text-white"} pointer d-md-none`}
          size={30}
        />
      </div>
      <div
        className={`${style.sidebar} ${showSidebar ? style.show : style.hide}`}
      >
        <AiOutlineClose
          size={30}
          className={`pointer ${style.close}`}
          onClick={() => setShowSidebar(false)}
        />
        <ul className="mx-0 mb-0 mt-5  p-0 ">
          {data.map((item, index) => (
            <li
              onClick={() => setShowSidebar(false)}
              className="my-3"
              key={index}
            >
              <HashLink className="fw-bold text-black" to={item.path}>
                {item.title}
              </HashLink>
            </li>
          ))}
          <div className="dropdown">
            <button
              class="langbtn  dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {lang}
            </button>
            <ul class="dropdown-menu">
              <li
                onClick={() => {
                  setLang("ar");
                  window.location.reload();
                }}
                class="dropdown-item"
              >
                {lang === "en" ? "arabic" : "العربية"}
              </li>
              <li
                onClick={() => {
                  setLang("en");
                  window.location.reload();
                }}
                class="dropdown-item"
              >
                {lang === "ar" ? "الإنجليزية" : "english"}
              </li>
            </ul>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
